
import { Component, Vue } from 'vue-property-decorator'
import { MenuAdd } from '@/types/crmmenu'
import { ElForm } from 'element-ui/types/form'
import { pInteger } from '@/utils/validate'

@Component({
  name: 'MenuAdd'
})
export default class extends Vue {
  private title = '新增菜单'

  private info: MenuAdd = {
    menuName: '',
    url: '',
    iconPath: '',
    isLeaf: '',
    menuCommon: '',
    orderNum: '',
    parentMenuId: null,
    buttonList: [],
    status: ''
  }

  private rules = {
    menuName: [{ required: true, message: '请输入菜单名称', trigger: ['blur', 'change'] }],
    parentMenuId: [{ required: true, message: '请选择父级菜单', trigger: ['change'] }],
    iconPath: [{ required: true, message: '请输入图标名称', trigger: ['blur', 'change'] }],
    isLeaf: [{ required: true, message: '请选择是否叶子节点', trigger: ['change'] }],
    orderNum: [{ validator: pInteger, trigger: ['blur', 'change'] }]
  }

  private butCode = ''
  private butName = ''
  private menuList = []
  private submitShow = false

  created () {
    this.menuListGet()
    if (this.$route.params.id) {
      this.title = '编辑菜单'
      this.detailsGet()
    }
  }

  // 详情
  detailsGet () {
    this.$axios.get(this.$apis.crmmenu.selectIparkAdminMenuByMenuId, {
      menuId: this.$route.params.id
    })
      .then((res) => {
        this.info = {
          menuName: res.menuName || '',
          url: res.url || '',
          iconPath: res.iconPath || '',
          isLeaf: res.isLeaf || '',
          menuCommon: res.menuCommon || '',
          parentMenuId: res.parentMenuId || '',
          orderNum: res.orderNum || '',
          buttonList: res.buttonList || [],
          status: res.status || '',
          menuId: res.menuId
        }
      })
  }

  // 菜单列表
  menuListGet () {
    this.$axios.get(this.$apis.crmmenu.selectIparkAdminMenuByList)
      .then((res) => {
        this.menuList = res.list || []
      })
  }

  handleClose (index: never) {
    this.info.buttonList.splice(index, 1)
  }

  // 添加按钮权限
  handleInputConfirm () {
    if (this.butCode && this.butName) {
      let codeRules = true
      this.info.buttonList.forEach((item) => {
        if (item.butCode === this.butCode) {
          this.$message({
            message: 'Code重复',
            type: 'warning'
          })
          codeRules = false
        }
      })
      if (codeRules) {
        this.info.buttonList.push({
          butCode: this.butCode,
          butName: this.butName
        })
        this.butCode = ''
        this.butName = ''
      }
    }
  }

  // 提交保存
  onSubmit () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        this.info.isLeaf === '1'
          ? this.info.iconPath = ''
          : this.info.parentMenuId = null
        const url = this.$route.params.id
          ? this.$apis.crmmenu.updateIparkAdminMenu
          : this.$apis.crmmenu.insertIparkAdminMenu
        this.$axios.post(url, this.info)
          .then(() => {
            this.submitShow = false
            this.$message({
              message: '保存成功',
              type: 'success'
            })
            this.$router.push({ name: 'crmMenuSetting' })
            this.$store.dispatch('getMenu')
          })
          .catch(err => {
            this.submitShow = false
            this.$message.error(err)
          })
      }
    })
  }
}
